<template>
  <div  class="mask">
    <div id="MaterialDetail" class="push-info" :title="IDType">
      <a-row :gutter="10" style="height: 100%">
        <a-col :span="24" class="phone-pre">
          <div class="phone-frame" style="padding-top: 20px; z-index: 10">
            <h2 style="font-weight: bold">{{ Model.Name }}</h2>
            <p style="color: #777">{{ Model.Author }}</p>
            <br />
            <div
              class="contents-p"
              style="word-break: break-all"
              v-html="Model.Contents"
            ></div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script src="../js/html2canvas.min.js"></script>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import imgapi from "@/Plugin/img.js";
import html2canvas from "html2canvas";
import local from "@/Plugin/local.js";
export default {
  name: "MaterialDetail",
  data() {
    return {
      Model: {
        fileList: [],
        Name: "",
        Author: "",
        Contents: "",
        Summary: "",
        ShareTime: "",
      },
      endOpen: false,
      NewID: "",
      imgUrl: "",
      upladW: 0,
      img: {},
      QRCodeType: 0,
      PromoteendOpen: false,
      SelectType: "checkbox",
      loading: false,
      towclass: "divfull2",
      UEditormask: "UEditormask2",
      IDTypes: 0,
      ID: "",
      maxLength: 100,
      OrderInfoID: "",
    };
  },
  props: {
    //组件属性
    IDType: Number,
    disabled: Boolean,
    MaterialID:String,
  },
  methods: {
    loadData: function () {
      var self = this;
      self.Model.ShareTime = util.formatDate3(new Date());
      if (self.IDTypes == 0) {
        var op = {
          url: "/MarketingModule/Material/GetMaterialInfo",
          data: {
            id: localStorage.ID,
          },
          OnSuccess: function (data) {
            self.Model.Author = data.data.Author;
            if (self.ID != "0") {
              self.Model.Name = data.data.Name;
              self.Model.Summary = data.data.Summary;
              //获取文本内容
              var htext = data.data.Content.ContextText;
              self.Model.Contents =
                (htext && htext.replace(/upload\//g, "upload2/")) || "";
              var ImgTopic = data.data.Logo;
              if (ImgTopic.indexOf("upload2") < 0) {
                ImgTopic = ImgTopic.replace(/upload\//g, "upload2/");
              }
              if (ImgTopic != "" && ImgTopic != null) {
                self.Model.fileList = [
                  {
                    name: ImgTopic,
                    response: ImgTopic,
                    status: "done",
                    uid: "0",
                    url: ImgTopic,
                  },
                ];
                self.Img = ImgTopic;
              } else {
                self.Model.fileList = [];
              }
            }
          },
        };
        http.Post(op);
      }
    },
    
    ImgCanvas: function () {
      var self = this;
      var bodymy = document.getElementById("MaterialDetail");
      setTimeout(() => {
        html2canvas(bodymy, {
          useCORS: true,
          allowTaint: false,
          width: window.width, // 绘制图片的宽 2倍
          dpi: window.devicePixelRatio * 3, // dpi  如果模糊的话 就把dpi和scale缩放的值调大 dpi越高生成的图片越大
          height: document.body.scrollHeight, // 绘制图片的高 2倍
        }).then((canvas) => {
          var res = canvas.toDataURL("image/jpeg", 0.75);
          var files = imgapi.dataURLtoFile(res);
            var Filename = "截图"+files.lastModified+".jpeg";
            var Newfile = new File([files], Filename, {
              name: Filename,
              type: "image/jpeg",
              size:files.size,
            });
            self.handleUpload(Newfile);
        });
      }, 500);
    },
    handleUpload: function (info) {
      var formData = new FormData();
      var self = this;
      formData.append("image", info);
      formData.append("JsonDataParams", "");
      formData.append("ProcessorName", "Image");
      var op = {
        url: "/FileServer/Upload",
        method: "post",
        processData: false,
        dataType: "json",
        data: formData,
        OnSuccess: function (data) {
          console.log(data);
          local.setlocal("img", data.data);
          self.$message.success("截图完成");
          self.$router.push({
            name: "PromoteMaterial_BindMaterial",
            query: { 
              QRMaterialID:localStorage.QRMaterialID,
              StartNum:localStorage.StartNum,
              EndNum:localStorage.EndNum
        }
          });
        },
      };
      http.Post(op);
    },
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function () {
    //生命周期，创建前
  },
  created: function () {
    //生命周期，创建完成
  },
  beforeMount: function () {
    //生命周期，组装前
  },
  mounted: function () {
    //生命周期，组装完成
    this.ID = localStorage.ID;
    this.loadData();
    if(localStorage.Action=="截图")
    {
      this.ImgCanvas();
    }
  },
  beforeUpdate: function () {
    //生命周期，data更新前
  },
  updated: function () {
    //生命周期，data更新完成
  },
  beforeDestroy: function () {
    //生命周期，销毁前
    if(localStorage.Action=="截图"){
        localStorage.type = "return";
    }
  },
  destroyed: function () {
    //生命周期，销毁完成
  },
};
</script>
<style scoped>
.right-cont {
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #f7f8f9;
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.push-info {
  z-index: 9;
  height: 100%;
}

.phone-pre {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3f7;
}

.phone-frame {
  align-items: center;
  width: 440px;
  height: 782px;
  margin: auto;
  overflow-x: hidden;
  padding: 0 10px;
  text-align: left;
  position: relative;
  overflow-y: auto;
  background-size: 100%;
  background: #fff;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 15px;
}

.iframe-p {
  width: 345px;
  padding: 35px 25px;
  height: 80vh;
  margin: 50px auto;
  text-align: left;
  overflow-y: auto;
  color: #000000;
}

.bm-view {
  width: 100%;
  height: 600px;
}

#map_title {
  position: absolute;
  z-index: 9999;
  left: 23px;
  right: 23px;
  height: 60px;
  background-color: #09f;
  padding-top: 10px;
  padding-left: 20px;
  color: white;
}

#map_sel {
  position: absolute;
  z-index: 9999;
  right: 106px;
  top: 92px;
}

#map_btns {
  position: absolute;
  z-index: 9999;
  bottom: 30px;
  width: 100%;
}

#map_btns input[type="button"] {
  width: 120px;
  height: 45px;
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 30px;
  opacity: 0.8;
}

.map_save {
  background-color: green;
  margin-right: 40px;
}

.map_cancel {
  background-color: #f15b5b;
}

.solid-btn {
  background: #32a9ff;
  color: #fff !important;
  padding: 4px 18px;
  border: none;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  margin-top: -3px;
}

.yj-storecon-template > div {
  height: 100px;
  width: 100px;
  overflow: hidden;
  text-align: center;
  flex-shrink: 0;
}

::v-deep .ql-align-justify {
  text-align: justify;
}
::v-deep .contents-p section {
  max-width: 100% !important;
}
::v-deep .contents-p img {
  max-width: 100% !important;
}
.contents-p > p {
  clear: both;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.content {
  margin: 15px 20px 15px 15px;
  padding-top: 0;
}

.form-search {
  display: block;
  list-style-type: disc;
  padding-left: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.box {
  border-top: none;
  box-shadow: none;
}

.right-btn {
  margin-top: 18px;
}

.line-btn {
  border: 1px solid #32a9ff;
  color: #32a9ff;
  padding: 7px 15px;
  background: #fff;
  transition: all 0.15s ease-in-out;
}

.gray-line-btn {
  border: 1px solid #e5e5e5;
  color: #666;
  padding: 7px 15px;
  background: #fff;
  transition: all 0.15s ease-in-out;
  margin-left: 20px;
}

.gray-line-btn {
  cursor: pointer;
}

.solid-btn {
  background: #32a9ff;
  color: #fff !important;
  padding: 4px 18px;
  border: none;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  margin-top: -3px;
}

.solid-btn:hover {
  background: #1b99f5;
  cursor: pointer;
  color: #fff;
}

.num {
  font-size: 26px;
  color: #32a9ff;
  padding: 5px;
  font-weight: 500;
}

.inners {
  padding: 10px;
  border: 1px solid #f3f4f5;
}

.search {
  overflow: hidden;
  background: #fbfbfb;
  margin: 10px auto;
  padding: 10px;
}

.line-btn:hover {
  background: #32a9ff;
  color: #fff;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.form-search {
  display: block;
  list-style-type: disc;
  padding-left: 0;
}

.mtb5 {
  margin: 5px 0;
}

.mtb10 {
  margin: 10px 0;
}

.mtb20 {
  margin: 20px 0;
}

.tr-middle td {
  vertical-align: middle;
}

body {
  background: #f3f5f5;
  font-family: Arial, Helvetica, sans-serif, "Microsoft YaHei", "ËÎÌå";
  font-size: 15px;
}

.sys-name {
  color: #00a2e9;
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-weight: 500;
  height: 68px;
  line-height: 68px;
}

.right-opt {
  height: 68px;
  line-height: 68px;
  cursor: pointer;
}

.top-top {
  margin-bottom: 10px;
  background: #fff;
  border-bottom: solid 1px #e0e5e5;
}

.top-center {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  background: #fff;
}

.content {
  margin: 15px 20px 15px 15px;
  padding-top: 0;
}

.box-header {
  border-bottom: 1px solid #f3f5f5;
  padding: 0 10px;
}

.box-header > .box-title {
  height: 62px;
  line-height: 62px;
}

.top-center a[href] {
  color: #32a9ff;
}

a {
  color: #32a9ff;
}

.pager li > a {
  border: none;
}

.main-right-search {
  padding: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #fbfbfb;
  padding-top: 0;
}

.form-search li {
  float: left;
  overflow: hidden;
  margin-right: 17px;
  width: 350px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  border: solid 1px #e0e5e5;
  border-radius: 4px;
  -moz-border-radius: 4px;
  list-style: none;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 0;
}

.form-search li label {
  float: left;
  width: 110px;
  color: #666;
  text-align: center;
  border-right: solid 1px #e0e5e5;
}

.form-search li input {
  float: left;
  border: none;
  background: none;
  outline: none;
  text-indent: 6px;
  -webkit-appearance: none;
  background-color: #ffffff;
  width: 235px;
  height: 26px;
  color: #333333;
  line-height: 28px;
}

.form-search-margin {
  margin-top: 12px;
}

.form-search li .inline-text {
  padding-left: 5px;
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager ul li {
  list-style: none outside none;
}

.pager li > a {
  border: none;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border-radius: 15px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.divfull {
  position: fixed;
  width: 90%;
  height: 90%;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  left: 10px;
  z-index: 10000;
}

.divfull2 {
  width: 700px;
  height: 600px;
}

.UEditormask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  display: block;
  left: 0px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.UEditormask2 {
  display: none;
}
</style>